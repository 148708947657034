export const gateway = "https://jeminsurance-bms-api.link.wawanesalife.com";
export const LoginUrlParameter = "https://stable-brokers.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=5mlphugc1rib55kb7j4vu4mh3d&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fjeminsurance-bms.link.wawanesalife.com%2Fredirect";
export const BrokerPoolClientParameter = "5mlphugc1rib55kb7j4vu4mh3d";
export const BrokerPoolDomainParameter = "stable-brokers";
export const BrokerPoolParameter = "ca-central-1_Q5DSclAvd";

export const brokerBranding = {
	"jeminsurance.link.wawanesalife.com": {
		a: {
			broker: "jeminsurance",
			accessibilityLink: "null",
			themeSwitcher: "null",
			privacyLink: "https://jeminsurance.ca/privacy-policy/",
			scope: "jeminsurance#branding#jeminsurance.link.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "info@jeminsurance.ca",
			video_link: "null",
			map2: "12-963 Henderson Hwy,Winnipeg,MB,R2K 2M3",
			environment_id: "stable",
			map1: "987 Portage Avenue,Winnipeg,MB,R3G 0R7",
			brands: "jeminsurance.link.wawanesalife.com",
			map4: "640 Central Avenue,Ste Rose du Lac,MB,R0L 1S0",
			map3: "9-35 Lakewood Boulevard,Winnipeg,MB,R2J 2M8",
			introduction: "null",
			phone: "204-774-4471",
			menuPhone: "button",
			environment: "stable",
			website: "https://jeminsurance.ca",
			ftcLink: "null",
			layout: "flat",
			g4: "null",
			photo3: "hide",
			photo4: "hide",
			formLabels: "show",
			photo1: "hide",
			photo2: "hide",
			tagline: "We’re here to help",
			approve_user: "link_jeminsurance",
			logoPosition: "menu",
			ab: "a",
			logo: "a.png",
			brand_name: "jeminsurance.link.wawanesalife.com",
		},
		b: {
			broker: "jeminsurance",
			accessibilityLink: "null",
			themeSwitcher: "null",
			privacyLink: "https://jeminsurance.ca/privacy-policy/",
			scope: "jeminsurance#branding#jeminsurance.link.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "info@jeminsurance.ca",
			video_link: "null",
			map2: "12-963 Henderson Hwy,Winnipeg,MB,R2K 2M3",
			environment_id: "stable",
			map1: "987 Portage Avenue,Winnipeg,MB,R3G 0R7",
			brands: "jeminsurance.link.wawanesalife.com",
			map4: "640 Central Avenue,Ste Rose du Lac,MB,R0L 1S0",
			map3: "9-35 Lakewood Boulevard,Winnipeg,MB,R2J 2M8",
			introduction: "null",
			phone: "204-774-4471",
			menuPhone: "button",
			environment: "stable",
			website: "https://jeminsurance.ca",
			ftcLink: "null",
			layout: "flat",
			g4: "null",
			photo3: "hide",
			photo4: "hide",
			formLabels: "show",
			photo1: "hide",
			photo2: "hide",
			tagline: "We’re here to help",
			approve_user: "link_jeminsurance",
			logoPosition: "menu",
			ab: "b",
			logo: "b.png",
			brand_name: "jeminsurance.link.wawanesalife.com",
		},
	},
};

//updated on Thu May 30 2024 10:25:21 GMT-0700 (Pacific Daylight Time)
